import React from 'react';

import './custom-button.css';

type CustomButtonProps = {
    className?: string;
    color: 'green' | 'white' | 'red';
    title: string;
    onClick: () => void;
    loading?: boolean;
    disabled?: boolean;
};

const CustomButton: React.FC<CustomButtonProps> = ({ className, color, title, onClick, loading = false, disabled = false }) => {
    return (
        loading ? (
            <button className={`${className} ${color}-btn btn`} onClick={onClick} disabled>
                <div className="spinner-border spinner-border-sm" role="status" />
            </button>
        ) : (
            <button className={`${className} ${color}-btn btn`} onClick={onClick} disabled={disabled}>
                {title}
            </button>
        )
    );
};

export default CustomButton;

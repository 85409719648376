// Login.tsx
import React, { useState } from 'react';
import CustomButton from '../../../components/custom-button/custom-button';
import GoogleButton from '../../../components/google-button/google-button';
import { isValidEmail } from '../../../utils/helpers';
import { isEmailRegistered } from '../../../services/authService';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from '../../../firebase';

import './login.css';

interface SignupProps {
    goBackToLogin: () => void;
    onLoginEmailSent: (email: string) => void;
}

const Signup: React.FC<SignupProps> = ({ goBackToLogin, onLoginEmailSent }) => {
    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [emailError, setEmailError] = useState<string | null>(null);
    const [firstnameError, setFirstnameError] = useState<string | null>(null);
    const [lastnameError, setLastnameError] = useState<string | null>(null);
    const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);

    const handleCheckboxChange = () => {
        setCheckboxChecked(!checkboxChecked);
    };
    
    const onClickRegister = async () => {
        if (!isValidEmail(email)) {
            setEmailError("Gib eine gültige E-Mail an");
        }
        if (firstname.length <= 0) {
            setFirstnameError("Darf nicht leer sein");
        }
        if (lastname.length <= 0) {
            setLastnameError("Darf nicht leer sein");
        }

        if (isValidEmail(email) && firstname.length > 0 && lastname.length > 0) {
            try {
                setIsLoading(true);
                setEmailError(null);
                setFirstnameError(null);
                setLastnameError(null);

                const existEmail: boolean = await isEmailRegistered(email);
                if (!existEmail) {
                    const actionCodeSettings = {
                        url: `${window.location.origin}/login`,
                        handleCodeInApp: true,
                    };
                    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
                    localStorage.setItem('email', email);
                    localStorage.setItem('firstname', firstname);
                    localStorage.setItem('lastname', lastname);
                    onLoginEmailSent(email);
                } else {
                    setEmailError("Die E-Mail ist bereits vergeben.");
                }
            } catch (error) {
                console.log('error while sign up over email:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="login">
            <h2 className="title">Willkommen!</h2>
            <p className="description">Erstelle Kostenfrei dein Konto, um loszulegen.</p>
            <div className="row mb-3">
                <div className="col-12 col-md-6">
                    <div className="text-start">
                        <label className="text-input-label form-check-label mb-2">Vorname</label>
                        <input
                            type="text"
                            className="form-control text-input"
                            placeholder="Max"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFirstname(e.target.value);
                                setFirstnameError(null);
                            }}
                        />
                    </div>
                    {firstnameError && (
                        <p className="email-error mt-2 ms-2 mb-0 text-start">{firstnameError}</p>
                    )}
                </div>
                <div className="col-12 col-md-6">
                    <div className="text-start">
                        <label className="text-input-label form-check-label mb-2">Nachname</label>
                        <input
                            type="text"
                            className="form-control text-input"
                            placeholder="Mustermann"
                            value={lastname}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setLastname(e.target.value);
                                setLastnameError(null);
                            }}
                        />
                    </div>
                    {lastnameError && (
                        <p className="email-error mt-2 ms-2 mb-0 text-start">{lastnameError}</p>
                    )}
                </div>
            </div>
            <div className="text-start">
                <label className="text-input-label form-check-label mb-2">E-Mail</label>
                <input
                    type="email"
                    className="form-control text-input"
                    placeholder="niklas@tradingonjourney.com"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setEmail(e.target.value);
                        setEmailError(null);
                    }}
                />
            </div>
            {emailError && (
                <p className="email-error mt-2 ms-2 mb-0 text-start">{emailError}</p>
            )}
            <div className="mt-3 form-check text-start">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="agbDatenschutzCheckbox"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                />
                <label className="login-terms-check-label" htmlFor="agbDatenschutzCheckbox">
                    Ich akzeptiere die <a href="/agb" target="_blan">AGB</a> und die <a href="/privacy-policy">Datenschutzbestimmungen</a>
                </label>
            </div>
            <CustomButton
                className="w-100 mb-3 mt-4"
                color="green"
                title="Registrieren"
                onClick={onClickRegister}
                loading={isLoading}
            />
            <div className="d-flex align-items-center mb-3">
                <hr className="flex-grow-1" />
                <span className="mx-2">ODER</span>
                <hr className="flex-grow-1" />
            </div>
            <GoogleButton className="w-100 mb-4" loading={isLoading} />
            <p className="signup">Du hast bereits ein Konto? <a style={{ cursor: "pointer" }} className="text-light" onClick={goBackToLogin}>Zur Anmeldung!</a></p>
        </div>
    );
};

export default Signup;

// BlogPostPage.tsx
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../utils/helpers";
import useUserStore from "../../stores/userStore";
import { fetchBlogById, fetchBlogComments, incrementBlogViews } from "../../services/blogService";
import CustomLoading from "../../components/custom-loading/custom-loading";
import useBlogStore from "../../stores/blogStore";
import BlogPostCommentCard from "./components/blog-post-comment-card";
import { auth } from "../../firebase";
import WriteCommentModal from "../../modals/write-comment-modal/write-comment-modal";
import CustomButton from "../../components/custom-button/custom-button";
import { Fade } from "react-awesome-reveal";
import { IoLogoInstagram } from "react-icons/io";
import NiklasPicture from "../../assets/images/author-picture.jpg";

import "./blog-post-page.css";

const BlogPostPage = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const commentDivRef = useRef<HTMLDivElement>(null);

    // Stores
    const { user } = useUserStore();
    const { selectedBlog, setSelectedBlog, comments, setComments } = useBlogStore();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingComments, setIsLoadingComments] = useState<boolean>(false);
    const [hasIncremented, setHasIncremented] = useState<boolean>(false);
    const [showWriteCommentModal, setShowWriteCommentModal] = useState<boolean>(false);

    // Increase blog post view after 30 seconds
    useEffect(() => {
        if (id && !hasIncremented) {
            const incrementViews = async () => {
                try {
                    await incrementBlogViews(id);
                    setHasIncremented(true);
                } catch (error) {
                    console.error('Error while increasing blog post view:', error);
                }
            };

            const timer = setTimeout(() => {
                incrementViews();
            }, 30000);

            return () => clearTimeout(timer);
        }
    }, [id, hasIncremented]);

    useEffect(() => {
        if (id) {
            const fetchBlog = async () => {
                try {
                    if (!selectedBlog || selectedBlog.id !== Number(id)) {
                        setIsLoading(true);
                    }
                    const blog = await fetchBlogById(Number(id));
                    setSelectedBlog(blog);
                } catch (error) {
                    console.log('Error while fetching blog data:', error);
                } finally {
                    setIsLoading(false);
                }
            }
            fetchBlog();

            if (user) {
                const fetchComments = async () => {
                    try {
                        setIsLoadingComments(true);
                        const token = await auth.currentUser?.getIdToken();
                        if (token) {
                            const fetchedComments = await fetchBlogComments(token, Number(id), 1, 10);
                            setComments(fetchedComments);
                        }
                    } catch (error) {
                        console.log('Error while fetching blog comments:', error);
                    } finally {
                        setIsLoadingComments(false);
                    }
                }
                fetchComments();
            }
        }
    }, [id]);

    const scrollToComments = () => {
        if (commentDivRef.current) {
            commentDivRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="page-container">
            <div className="container mt-5 mb-5 blog-post">
                {isLoading ? (
                    <CustomLoading style={{ minHeight: "40vh" }} />
                ) : (
                    selectedBlog && (
                        <>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <img className="blog-post-image" src={selectedBlog.picture} alt="Blog Post" />
                                </div>
                                <div className="col-12 col-md-6">
                                    <p className="description">{selectedBlog.description}</p>
                                    <div className="d-flex align-items-center mt-4 mb-2 blog-post-info-box">
                                        <img src={NiklasPicture} alt="Niklas Foto" className="blog-post-author-picture" />
                                        <div className="mt-2 mb-2 ms-4 align-items-center">
                                            <div className="d-flex align-items-center mb-1">
                                                <p className="blog-post-author">Autor: Niklas Schmidt</p>
                                                <a className="blog-post-social" href="https://www.instagram.com/_niklas10_" target="_blank" rel="noopener noreferrer">
                                                    <IoLogoInstagram />
                                                </a>
                                            </div>
                                            <p className="blog-post-date mb-1">Geschätzte Lesezeit: {selectedBlog.read_time} Minuten</p>
                                            <p className="blog-post-date">Aufrufe: {selectedBlog.views}</p>
                                        </div>
                                    </div>
                                    <CustomButton className="w-100 mt-3" color="green" title="Kommentare" onClick={scrollToComments} />
                                </div>
                            </div>
                            <div className="mt-4 mb-4" style={{ height: 1, backgroundColor: "rgba(255,255,255,0.1)" }} />
                            {selectedBlog.text && (
                                <div className="blog-post-card">
                                    <p className="blog-post-date text-end mb-3">Veröffentlicht am: {formatDate(selectedBlog.created_at)}</p>
                                    <Fade triggerOnce>
                                        <div dangerouslySetInnerHTML={{ __html: selectedBlog.text }} />
                                    </Fade>
                                </div>
                            )}
                            <div className="d-flex mt-5 mb-4 align-items-center" ref={commentDivRef}>
                                <h3 className="amount-title">{user ? `Kommentare (${comments.length})` : `Kommentare`}</h3>
                                <a className="ms-3 write-comment-btn" onClick={() => {
                                    if (user) {
                                        setShowWriteCommentModal(true);
                                    } else {
                                        navigate("/login");
                                    }
                                }}>{user ? 'Kommentar verfassen' : 'Kostenlos Anmelden'}</a>
                            </div>
                            {!user && (
                                <p>Registriere dich <strong>kostenlos</strong>, um Kommentare zu lesen und zu schreiben.</p>
                            )}
                            {isLoadingComments ? (
                                <CustomLoading style={{ minHeight: "40vh" }} />
                            ) : (
                                comments.map(comment => {
                                    return (
                                        <BlogPostCommentCard
                                            id={comment.id}
                                            userId={comment.user_id}
                                            firstname={comment.firstname}
                                            lastname={comment.lastname}
                                            text={comment.comment_text}
                                            createdAt={comment.created_at}
                                        />
                                    )
                                })
                            )}
                        </>
                    )
                )}
                {showWriteCommentModal && id && (
                    <WriteCommentModal
                        blogId={Number(id)}
                        onClose={() => setShowWriteCommentModal(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default BlogPostPage;

import React, { useEffect } from 'react';

const TradingViewWidget: React.FC = () => {
    useEffect(() => {
        // Create script element
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
        script.async = true;
        script.type = 'text/javascript';
        script.innerHTML = JSON.stringify({
            "symbols": [
                {
                    "description": "",
                    "proName": "FX:EURUSD"
                },
                {
                    "description": "",
                    "proName": "FX:EURJPY"
                },
                {
                    "description": "",
                    "proName": "FX:EURAUD"
                },
                {
                    "description": "",
                    "proName": "FX:GBPUSD"
                },
                {
                    "description": "",
                    "proName": "FX:GBPJPY"
                },
                {
                    "description": "",
                    "proName": "FX:GBPAUD"
                },
                {
                    "description": "",
                    "proName": "FX:AUDUSD"
                },
                {
                    "description": "",
                    "proName": "FX:AUDJPY"
                },
                {
                    "description": "",
                    "proName": "FX:USDCAD"
                },
                {
                    "description": "",
                    "proName": "FX:USDJPY"
                },
                {
                    "description": "",
                    "proName": "OANDA:XAUUSD"
                },
                {
                    "description": "",
                    "proName": "CAPITALCOM:DE40"
                },
                {
                    "description": "",
                    "proName": "CAPITALCOM:US30"
                },
                {
                    "description": "",
                    "proName": "TVC:USOIL"
                },
                {
                    "description": "",
                    "proName": "TVC:SPX"
                }
            ],
            "showSymbolLogo": true,
            "isTransparent": true,
            "displayMode": "adaptive",
            "colorTheme": "dark",
            "locale": "de_DE"
        });

        // Append script to the widget container
        document.querySelector('.tradingview-widget-container__widget')?.appendChild(script);

        return () => {
            // Cleanup the script when the component is unmounted
            document.querySelector('.tradingview-widget-container__widget')?.removeChild(script);
        };
    }, []);

    return (
        <div className="tradingview-widget-container">
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
};

export default TradingViewWidget;

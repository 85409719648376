// AGBPage.tsx
import "./agb-page.css";

const AGBPage = () => {
    return (
        <div className="page-container">
            <div className="container mt-5 mb-5 agb">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h1 className="title">Allgemeine Geschäftsbedingungen (AGB)</h1>
                    </div>
                </div>
                <div className="mt-4 mb-5" style={{ height: 1, backgroundColor: "rgba(255,255,255,0.1)" }} />
                <p>
                    Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") regeln die Nutzung der Dienste von Trading On Journey, einer Marke von Bitpalm Computer Systems & Communication
                    Equipment Software Design LLC (nachfolgend "wir" oder "uns").
                    Durch die Nutzung unserer Dienste stimmst du diesen AGB zu.
                </p>

                <h3 className="mt-4">1. Geltungsbereich</h3>
                <p>
                    Diese AGB gelten für alle Nutzer unserer Dienste.
                </p>

                <h3>2. Leistungen</h3>
                <p>
                    Wir bieten Dienste im Bereich Trading-Mentoring an. Die genauen Leistungen sind auf unserer Website beschrieben.
                </p>

                <h3>3. Zahlungsbedingungen</h3>
                <p>
                    Die Zahlung erfolgt über den Anbieter Stripe. Weitere Informationen zu den Zahlungsbedingungen findest du auf unserer Website.
                </p>

                <h3>4. Haftungsausschluss</h3>
                <p>
                    Wir übernehmen keine Haftung für finanzielle Verluste oder Schäden, die durch die Nutzung unserer Dienste entstehen.
                </p>

                <h3>5. Änderungen der AGB</h3>
                <p>
                    Wir behalten uns das Recht vor, diese AGB jederzeit zu ändern. Änderungen werden auf unserer Website veröffentlicht.
                </p>

                <h3>6. Schlussbestimmungen</h3>
                <p>
                    Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, berührt dies nicht die Wirksamkeit der übrigen Bestimmungen.
                </p>

                <p className="mt-5">
                    Bei Fragen zu unseren AGB stehen wir dir gerne zur Verfügung. Kontaktiere uns unter business@tradingonjourney.com.
                </p>
            </div>
        </div>
    );
};

export default AGBPage;

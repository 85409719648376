import Lottie from "lottie-react";
import React from "react";
import ChartLoadingAnimation from "../../assets/anims/chart-loading.json";

interface CustomLoadingProps {
    style?: React.CSSProperties;
}

const CustomLoading: React.FC<CustomLoadingProps> = ({ style }) => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={style}>
            <div>
                <Lottie style={{ width: 100 }} animationData={ChartLoadingAnimation} loop={true} />
            </div>
        </div>
    );
};

export default CustomLoading;

// blogService.ts
import { IBlog, IBlogComment } from "../stores/blogStore";
import { API_URL } from "../utils/constants";

export const fetchBlogs = async (
  page: number = 1,
  pageSize: number = 10,
  category: string | null = null
): Promise<IBlog[]> => {
  try {
    let url = `${API_URL}/blogs?page=${page}&pageSize=${pageSize}`;
    if (category) {
      url += `&category=${encodeURIComponent(category)}`;
    }

    const response = await fetch(url);
    if (!response.ok) return [];

    const data = await response.json();
    return data.blogs as IBlog[];
  } catch (error) {
    console.error("Error fetching blogs:", error);
    throw error;
  }
};

export const fetchBlogById = async (blogId: number): Promise<IBlog> => {
  try {
    const response = await fetch(`${API_URL}/blogs/${blogId}`);
    if (!response.ok) {
      throw new Error("Blog not found.");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching specific blog:", error);
    throw error;
  }
};

export const incrementBlogViews = async (blogId: string) => {
  try {
    const response = await fetch(`${API_URL}/blogs/${blogId}/views`, {
      method: "PATCH",
    });
    return await response.json();
  } catch (error) {
    console.error("Error updating blog views:", error);
    throw error;
  }
};

export const fetchBlogComments = async (
  token: string,
  blogId: number,
  page: number = 1,
  pageSize: number = 10
): Promise<IBlogComment[]> => {
  try {
    const response = await fetch(
      `${API_URL}/blogs/${blogId}/comments?page=${page}&pageSize=${pageSize}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) return [];

    const data = await response.json();
    return data.comments as IBlogComment[];
  } catch (error) {
    console.error("Error fetching comments:", error);
    throw error;
  }
};

export const createComment = async (
  token: string,
  blogId: number,
  commentText: string
): Promise<IBlogComment | null> => {
  try {
    const response = await fetch(`${API_URL}/blogs/${blogId}/comments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ commentText }),
    });

    if (!response.ok) return null;

    const data = await response.json();
    return data.comment;
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error;
  }
};

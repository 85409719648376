import React, { useEffect, useState, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import gsap from 'gsap';
import ReactGA from 'react-ga4';
import TopBar from '../components/topbar/topbar';
//import Banner from '../components/banner/banner';
import Footer from '../components/footer/footer';
import CustomLoading from '../components/custom-loading/custom-loading';

const Root: React.FC = () => {
    const location = useLocation();
    const cursorLightRef = useRef<HTMLDivElement | null>(null);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: document.title });
    }, [location]);

    useEffect(() => {
        const handleLoad = () => {
            setIsLoading(false);
        };

        document.addEventListener('DOMContentLoaded', handleLoad);
        window.addEventListener('load', handleLoad);

        return () => {
            document.removeEventListener('DOMContentLoaded', handleLoad);
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    useEffect(() => {
        if (location.pathname === "/") {
            const handleMouseMove = (event: MouseEvent) => {
                const cursorLight = cursorLightRef.current;
                if (cursorLight) {
                    const x = event.clientX;
                    const y = event.clientY;
                    const isTouchDevice = 'ontouchstart' in window;

                    if (!isTouchDevice) {
                        const xPercent = Math.round(x / window.innerWidth * 100);
                        const yPercent = Math.round(y / window.innerHeight * 100);

                        gsap.to(cursorLight, {
                            duration: 1.5,
                            '--x': `${xPercent}%`,
                            '--y': `${yPercent}%`,
                            ease: 'power2.out'
                        });
                    }
                }
            };

            window.addEventListener('mousemove', handleMouseMove);

            return () => {
                window.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, [location]);

    return (
        <>
            {isLoading ? (
                <CustomLoading style={{ height: "100vh" }} />
            ) : (
                <>
                    {/*<Banner text="Aktuell werden 10 Plätze für das Lifetime-Mentoring vergeben. Bewirb dich jetzt bis zum 24.03.2024!" />*/}
                    <TopBar />
                    <div id="detail">
                        <Outlet />
                    </div>
                    <Footer />
                    {location.pathname === "/" && (
                        <div id="cursor-light" className="ct-div-block" ref={cursorLightRef} />
                    )}
                </>
            )}
        </>
    );
};

export default Root;

// ApplicationPage.tsx
import { useMemo, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import useUserStore, { IUser } from "../../stores/userStore";
import { createApplication } from "../../services/userService";
import useAuthStore from "../../stores/authStore";
import { auth } from "../../firebase";
import CustomLoading from "../../components/custom-loading/custom-loading";

import "./application-page.css";

const ApplicationPage = () => {
    // Stores
    const { firebaseUser } = useAuthStore();
    const { user, setUser } = useUserStore();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useCalendlyEventListener({
        onEventScheduled: async (e) => {
            try {
                const payload = e.data.payload;
                setIsLoading(true);

                const token = await auth.currentUser?.getIdToken();
                if (token && firebaseUser?.uid) {
                    const application = await createApplication(token, firebaseUser.uid);
                    setUser({
                        ...user,
                        application: application
                    } as IUser)
                }
            } catch (error) {
                console.log('Error while trying to create an application for the user:', error);
            } finally {
                setIsLoading(false);
            }
        },
    });

    const getPageTitle = useMemo(() => {
        if (user?.application?.status === "open") return "Termin bestätigt!";
        else if (user?.application?.status === "accepted") return "Herzlichen Glückwunsch!";
        else return "Bewerbung"
    }, [user]);

    return (
        <div className="page-container">
            <div className="container mt-5 mb-5 application">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h1 className="title">{getPageTitle}</h1>
                    </div>
                    <div className="col-12 col-md-6">
                        <p className="description">Überprüfe hier deinen aktuellen Bewerbungsstatus oder bewirb dich jetzt, falls du es noch nicht getan hast!</p>
                    </div>
                </div>
                <div className="mt-4 mb-5" style={{ height: 1, backgroundColor: "rgba(255,255,255,0.1)" }} />
                {isLoading ? (
                    <CustomLoading style={{ minHeight: "20vh" }} />
                ) : (
                    <>
                        {user && !user.application && (
                            <div>
                                <p className="text-light"><strong>Glückwunsch!</strong> Du bist nur noch wenige Schritte von deiner Bewerbung entfernt. Um den nächsten Schritt auf deiner Reise zu beginnen, hast du die Möglichkeit, über den untenstehenden Kalender dein persönliches Bewerbungsgespräch mit mir zu buchen.
                                    <br></br><br></br>
                                    <strong>Wie wird das Bewerbungsgespräch ablaufen?</strong><br></br>
                                    Es handelt sich nicht um ein klassisches Bewerbungsgespräch, sondern vielmehr um ein lockeres Kennenlerngespräch. Ich werde dir den Ablauf des Mentoring Programms erläutern, und anschließend hast du die Möglichkeit, mir Fragen zu stellen. Nicht mehr und nicht weniger.
                                    <br></br><br></br>
                                    Wenn du nach unserem Gespräch dabei sein möchtest, werde ich dir am <strong>24.03.2024</strong> persönlich per E-Mail mitteilen, ob du für das Lifetime-Mentoring ausgewählt wurdest oder nicht. Du erhältst auf jeden Fall eine Rückmeldung, unabhängig davon, ob es sich um eine Zusage oder Absage handelt. Im Falle einer positiven Zusage hast du die einmalige Gelegenheit, das Lifetime-Mentoring-Programm hier auf der Webseite zu erwerben.</p>
                                <InlineWidget
                                    styles={{ height: '100vh' }}
                                    url="https://calendly.com/tradingonjourney/lifetime-mentoring-bewerbungsgespraech"
                                    prefill={{
                                        email: user.email,
                                        firstName: user.firstname,
                                        lastName: user.lastname
                                    }}
                                    pageSettings={{
                                        backgroundColor: '0E1225',
                                        hideEventTypeDetails: false,
                                        hideLandingPageDetails: false,
                                        primaryColor: '56A476',
                                        textColor: 'E4E4E7'
                                    }}
                                />
                            </div>
                        )}
                        {user && user.application && (
                            <div>
                                <p className="text-light">Du hast erfolgreich dein persönliches Bewerbungsgespräch mit mir gebucht. Dies ist ein wichtiger Schritt auf deiner Reise zu unserem Mentoring Programm.
                                    <br></br><br></br>
                                    <strong>Was erwartet dich beim Bewerbungsgespräch?</strong>
                                    <br></br><br></br>
                                    Unser Gespräch wird kein traditionelles Bewerbungsgespräch sein, sondern eher ein informelles Kennenlernen. Ich werde dir den Ablauf des Mentoring Programms erklären, und danach hast du die Gelegenheit, mir Fragen zu stellen.
                                    <br></br><br></br>
                                    <strong>Was passiert als nächstes?</strong>
                                    <br></br><br></br>
                                    Wenn du nach unserem Gespräch dabei sein möchtest, werde ich dich auf die Liste setzen. Am 24.03.2024 werde ich dir dann persönlich per E-Mail mitteilen, ob du die Möglichkeit hast, beim Lifetime-Mentoring dabei zu sein oder nicht.
                                    <br></br><br></br>
                                    Ich freue mich darauf, dich kennenzulernen!
                                    <br></br><br></br>
                                    Beste Grüße<br></br>
                                    Niklas</p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ApplicationPage;

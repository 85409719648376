import { API_URL } from "../utils/constants";

export interface ContactFormData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export const sendContactForm = async (
  formData: ContactFormData
): Promise<boolean> => {
  try {
    const response = await fetch(`${API_URL}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    return response.ok;
  } catch (error) {
    console.error("Error sending contact form:", error);
    throw new Error("Error sending contact form");
  }
};

import { API_URL } from "../utils/constants";

export const isEmailRegistered = async (email: string): Promise<boolean> => {
  try {
    const response = await fetch(`${API_URL}/check-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
      }),
    });

    return response.status === 200;
  } catch (error) {
    console.error("Error during the API call:", error);
    return false;
  }
};

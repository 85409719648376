import React, { useCallback, useState } from 'react';
import { createComment } from '../../services/blogService';
import { auth } from "../../firebase";
import useBlogStore from '../../stores/blogStore';
import CustomButton from '../../components/custom-button/custom-button';

import './write-comment-modal.css';

interface ModalProps {
    blogId: number;
    onClose: () => void;
}

const WriteCommentModal: React.FC<ModalProps> = ({ blogId, onClose }) => {
    // Stores
    const { comments, setComments } = useBlogStore();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [comment, setComment] = useState<string>("");

    const onClickSend = useCallback(async () => {
        if (comment.trim().length > 0) {
            try {
                setIsLoading(true);
                const token = await auth.currentUser?.getIdToken();
                if (token) {
                    const createdComment = await createComment(token, blogId, comment);
                    if (createdComment) {
                        setComments([createdComment, ...comments]);
                        onClose();
                    }
                } else {
                    alert("Es ist ein Fehler beim speichern deines Kommentares aufgetreten. Bitte versuche es erneut.");
                }

            } catch (error) {
                console.log('Error while creating blog post comment:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [blogId, comment]);

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">Kommentar verfassen</h3>
                <textarea
                    className="form-control textarea-input"
                    placeholder="Tippe hier dein Kommentar ein..."
                    value={comment}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setComment(e.target.value);
                    }}
                />
                <div className="text-end mt-4">
                    <CustomButton className="me-2" color="green" title="Absenden" onClick={onClickSend} loading={isLoading} disabled={isLoading || comment.trim().length <= 0} />
                    {!isLoading && (
                        <CustomButton color="white" title="Schließen" onClick={onClose} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default WriteCommentModal;

import React, { useRef, useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import { LazyLoadImage } from "react-lazy-load-image-component";
import VideoThumbnail from '../../assets/images/video-thumbnail.webp';

import styles from './VideoPlayer.module.css';

type VideoPlayerProps = {
    className?: string | undefined;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({ className }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
    };

    return (
        <div className={`${className} ${styles.videoContainer}`}>
            {!playVideo ? (
                <div className={styles.thumbnailContainer} onClick={() => {
                    setPlayVideo(true);
                    setIsPlaying(true);
                }}>
                    <LazyLoadImage src={VideoThumbnail} alt="Video Thumbnail" className={styles.thumbnail} />
                    <div className={styles.playButton}>
                        <FaPlay className={styles.playButtonIcon} />
                    </div>
                </div>
            ) : (
                <video ref={videoRef} width="100%" autoPlay onClick={togglePlayPause} onContextMenu={handleContextMenu}>
                    <source src="https://tradingonjourney.blr1.cdn.digitaloceanspaces.com/assets/hello-video.mov" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )}
        </div>
    );
};

export default VideoPlayer;

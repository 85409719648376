// BlogPage.tsx
import BlogCard from "./components/blog-card";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchBlogs } from "../../services/blogService";
import CustomLoading from "../../components/custom-loading/custom-loading";
import useBlogStore from "../../stores/blogStore";

import "./blog-page.css";

const BlogPage = () => {
    const navigate = useNavigate();

    // Stores
    const { blogs, setBlogs } = useBlogStore();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const loadBlogs = async () => {
            try {
                if (blogs.length <= 0) {
                    setIsLoading(true);
                }
                const fetchedBlogs = await fetchBlogs(1, 100);
                setBlogs(fetchedBlogs);
            } catch (error) {
                console.log('Error while fetching blog data:', error);
            } finally {
                setIsLoading(false);
            }
        }
        loadBlogs();
    }, [])

    const onNavigateToBlogPost = (id: number) => {
        navigate(`/blog/${id}`);
    }

    return (
        <div className="page-container">
            <div className="container mt-5 mb-5 blog">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h1 className="title">Blog</h1>
                    </div>
                    <div className="col-12 col-md-6">
                        <p className="description">Entdecke eine Vielfalt an Trading-Artikeln! Erhalte tiefe Einblicke und praktische Tipps, ganz gleich, ob du Anfänger bist oder deine Fähigkeiten erweitern möchtest. Alle Beiträge sind kostenlos und sofort verfügbar, um deine Trading-Kenntnisse zu bereichern.</p>
                    </div>
                </div>
                <div className="mt-4 mb-5" style={{ height: 1, backgroundColor: "rgba(255,255,255,0.1)" }} />
                {isLoading ? (
                    <div className="mt-5">
                        <CustomLoading style={{ minHeight: "40vh" }} />
                    </div>
                ) : (
                    <>
                        <h3 className="amount-title mt-5 mb-4 ms-3">Blogbeiträge ({blogs.length})</h3>
                        <div className="row">
                            {blogs.map(blog => {
                                return (
                                    <div className="col-12 col-md-4">
                                        <BlogCard
                                            id={blog.id}
                                            image={blog.picture}
                                            title={blog.title}
                                            description={blog.description}
                                            readTime={blog.read_time}
                                            createdAt={blog.created_at}
                                            onClick={() => { onNavigateToBlogPost(blog.id) }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default BlogPage;

// FAQSection.tsx
import React, { useState } from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import CustomButton from '../custom-button/custom-button';
import { useNavigate } from 'react-router-dom';

import './faq-section.css';
import { DISCORD_URL } from '../../utils/constants';

type FAQ = {
    question: string;
    answer: string;
};

interface FAQSectionProps {
    showAllQuestions: boolean;
    showMoreButton: boolean;
}

const FAQSection: React.FC<FAQSectionProps> = ({ showAllQuestions, showMoreButton }) => {
    const navigate = useNavigate();

    const [activeFAQ, setActiveFAQ] = useState<number | null>(null);

    const faqs: FAQ[] = [
        {
            question: "Wann startet das Lifetime-Mentoring?",
            answer: "Das Lifetime-Mentoring ist bereits gestartet, dennoch hast du die Möglichkeit der aktuellen aktiven Lifetime-Mentoring Gruppe beizutreten."
        },
        {
            question: "Wie viel kostet das Lifetime-Mentoring Programm?",
            answer: "Den Preis erfährst du im persönlichen Bewerbungs- oder Beratungsgespräch mit mir. Um mehr zu erfahren, buche am besten ein kurzes Gespräch mit mir. Der Preis ist für alle Teilnehmer gleich."
        },
        {
            question: 'Kann man das Lifetime-Mentoring auch nebenberuflich machen?',
            answer: 'Ja das Lifetime-Mentoring ist auch nebenberuflich möglich. Tatsächlich ist es oft ratsam, mit dem Trading nebenberuflich zu beginnen, bevor man sich vollständig darauf konzentriert. Durch den Einstieg in das Trading nebenberuflich kannst du die Grundlagen des Handels erlernen, deine Fähigkeiten entwickeln und erste Erfahrungen sammeln, während du weiterhin deinem Hauptberuf nachgehst. Dies ermöglicht es dir, finanzielle Sicherheit zu bewahren, während du dich in die Welt des Tradings einarbeitest.',
        },
        {
            question: 'Brauche ich Vorkenntnisse, um am Lifetime-Mentoring teilzunehmen?',
            answer: 'Nein, du brauchst keine Vorkenntnisse. Das Konzept ist speziell darauf ausgelegt, dir auch ohne vorherige Trading-Erfahrung, alles Notwendige beizubringen. Wir beginnen mit den Grundlagen und arbeiten uns Schritt für Schritt zu fortgeschrittenen Techniken vor. Das Ziel ist es, dich in kürzester Zeit zu einem profitablen Vollzeit-Trader auszubilden.',
        },
    ];

    if (showAllQuestions) {
        faqs.push(
            {
                question: "Kann ich das Lifetime-Mentoring Programm in Raten zahlen?",
                answer: "Ja, du hast die Möglichkeit, das Lifetime-Mentoring-Programm in Raten zu zahlen. Du kannst zwischen einer Laufzeit von 6 oder 12 Monaten wählen, damit jeder die Möglichkeit hat, daran teilzunehmen."
            },
            {
                question: 'Wie lange geht das Lifetime-Mentoring?',
                answer: 'Die Lifetime-Mentoring Ausbildung ist darauf ausgelegt, dich solange zu begleiten, bis du die Fähigkeiten und das Vertrauen entwickelt hast, ein profitabler Vollzeit-Trader zu sein. Insgesamt ist das Ziel des Lifetime-Mentorings, dich nicht nur zu einem Vollzeit-Trader zu machen, sondern auch sicherzustellen, dass du langfristig konstant und profitabel handeln kannst.',
            },
            {
                question: "Ist Trading für jeden erlernbar?",
                answer: "Ja, grundsätzlich kann jeder das Trading erlernen, doch der Erfolg hängt von mehreren wichtigen Faktoren ab: Du benötigst umfassende Marktkenntnisse, analytische Fähigkeiten, effektives Risikomanagement, emotionale Kontrolle und eine solide Handelsstrategie. All das und mehr vermittel ich dir in der Lifetime-Mentoring-Ausbildung. Zudem ist der regelmäßige Austausch und das Feedback von jemandem, der im Trading erfahren ist, sowie der Austausch mit anderen Teilnehmern in einer Trading-Community, entscheidend für langfristigen Erfolg."
            },
            {
                question: "Wie viel Startkapital benötige ich für profitables Trading?",
                answer: "Viele denken immer, dass man als Anfänger im Trading viel Geld benötigt. Doch wichtiger ist es, dass du dir das nötige Wissen aneignest und anfängst, im Daytrading kontinuierlich profitabel zu sein. Sobald du die Fähigkeiten erlernt hast, wird das Kapital zu deinem kleinsten Problem."
            },
            {
                question: 'Wie lange dauert es im Lifetime-Mentoring, bis man vom Trading leben kann?',
                answer: 'Die Zeit, die du benötigst, um erfolgreich vom Trading leben zu können, variiert von Person zu Person. In der Lifetime-Mentoring Ausbildung wirst du dank meines Wissens und meiner Erfahrung schneller die notwendigen Fähigkeiten erlernen. Es ist aber wichtig zu verstehen, dass Trading eine langfristige Disziplin ist und kein schneller Weg zum Reichtum. Erfolg im Trading erfordert fundiertes Wissen, praktische Erfahrung und emotionale Disziplin.',
            },
            {
                question: "Gibt es eine Rückerstattungsmöglichkeit, falls ich das Lifetime-Mentoring abbreche?",
                answer: "Nein, und zwar aus dem Grund, dass ich von Anfang an nach engagierten und lernbereiten Studenten suche, die bereit sind, den gesamten Weg mit mir zu gehen. Wenn du bereits vor dem Start darüber nachdenkst, das Programm abzubrechen, könnte das Lifetime-Mentoring möglicherweise nicht die beste Wahl für dich sein. Mein Ziel ist es, dich kontinuierlich zu unterstützen, bis du erfolgreich und profitabel an den Finanzmärkten handeln kannst."
            },
        );
    }

    const toggleFAQ = (index: number) => {
        setActiveFAQ(activeFAQ === index ? null : index);
    };

    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-12 col-lg-4 mb-4 text-start">
                    <h2 className="faq-header">Frequently Asked Questions</h2>
                    <p className="faq-subtext">
                        Weitere Fragen? Schau dir alle FAQ's an oder tritt unserem Discord Server bei.
                    </p>
                    <div className="d-flex mt-5 align-items-center faq-button-container">
                        {showMoreButton && (
                            <CustomButton className="me-2" color={'white'} title={'Alle Anzeigen'} onClick={() => {
                                navigate("/faq");
                            }} />
                        )}
                        <CustomButton color={'green'} title="Join Discord" onClick={() => {
                            window.open(DISCORD_URL, '_blank');
                        }} />
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    {faqs.map((faq, index) => (
                        <div key={index} className="faq-item">
                            <div className="faq-question-box" onClick={() => toggleFAQ(index)}>
                                <p className="faq-question">{faq.question}</p>
                                <span className="faq-toggle">
                                    {activeFAQ === index ? <FaTimes size={10} /> : <FaPlus size={10} />}
                                </span>
                            </div>
                            <div className={`faq-answer ${activeFAQ === index ? 'show' : ''}`}>
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQSection;

// BlogCard.tsx
import React, { useMemo } from 'react';
import { calculateTimeAgo } from '../../../utils/helpers';
import useUserStore from '../../../stores/userStore';

import './blog-post-comment-card.css';

type BlogPostCommentCardProps = {
    id: number;
    userId: string;
    firstname: string;
    lastname?: string;
    text: string;
    createdAt: string;
};

const BlogPostCommentCard: React.FC<BlogPostCommentCardProps> = ({ id, userId, firstname, lastname, text, createdAt }) => {
    const { user } = useUserStore();

    const displayName = useMemo(() => {
        return lastname
            ? `${firstname} ${lastname.charAt(0)}.`
            : firstname;
    }, [firstname, lastname])

    return (
        <div className="comment d-flex mb-3" key={id}>
            {/*<div className="comment-vote d-flex flex-column justify-content-center align-items-center me-3">
                <button className="btn btn-sm btn-outline-primary">+</button>
                <span className="my-1">2</span>
                <button className="btn btn-sm btn-outline-secondary">-</button>
    </div>*/}
            <div className="comment-body ms-2">
                <div className="comment-header mb-1">
                    <span className="comment-username fw-bold me-2">{displayName}</span>
                    {user?.id === userId && (
                        <span className="badge bg-secondary">du</span>
                    )}
                    <span className="comment-timestamp ms-2">{calculateTimeAgo(createdAt)}</span>
                    {/*<button className="btn btn-link text-danger p-0 ms-auto">Delete</button>*/}
                </div>
                <div className="comment-content mt-4">
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
};

export default BlogPostCommentCard;

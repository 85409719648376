// HomePage.tsx
import "./home-page.css";

import Logo from '../../assets/images/tradingonjourney-logo.png';
import FTMOLogo from '../../assets/images/ftmo-logo.png';
import TradingViewLogo from '../../assets/images/tradingview-logo.png';
import RoboForexLogo from '../../assets/images/roboforex-logo.png';
import BitgetLogo from '../../assets/images/bitget-logo.png';
import MyFxBookLogo from '../../assets/images/myfxbook-logo.png';
import TheTradingPitLogo from '../../assets/images/thetradingpit-logo.png';
//import DefaultUserAvatar from '../../assets/images/default-user.jpg';
import CustomButton from '../../components/custom-button/custom-button';
import VideoPlayer from '../../components/video-player/video-player';
import FAQSection from '../../components/faq-section/faq-section';
import Section from '../../components/section/section';
import useUserStore from '../../stores/userStore';
import { useNavigate } from 'react-router-dom';
import { PopupWidget } from "react-calendly";
import { useEffect, useState } from 'react';
import { formatDate } from '../../utils/helpers';
import TradingViewWidget from '../../components/tradingview-widget/tradingview-widget';

const HomePage = () => {
    const navigate = useNavigate();
    const { user } = useUserStore();

    const onClickApply = () => {
        if (user) {
            navigate("/application");
        } else {
            navigate("/login");
        }
    }

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    return (
        <div className="page-container" id="home-page">
            <div className="mt-5 text-center">

                {/* LOGO & WELCOME TEXT */}
                <img className="home-page-logo" src={Logo} alt="Logo" />
                <p className="home-page-subtitle m-2">Es ist an der Zeit, deine neue Reise zu beginnen und das Trading <b><u>professionell</u></b> zu erlernen.<br></br>Profitiere von meinem Wissen aus sieben Jahren Markterfahrung und handel mit mir, deinen Weg zur finanziellen Freiheit.</p>

                {/* WELCOME VIDEO */}
                <Section id="about">
                    <VideoPlayer className="mt-5 mb-5" />
                </Section>

                {/* PARTNER LOGOS */}
                <Section>
                    <div className="container partner-logos mt-5">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-6 col-md-2 logo-container">
                                <a href="https://my.roboforex.com/de/?a=edjk" target="_blank" rel="noopener noreferrer">
                                    <img src={RoboForexLogo} alt="RoboForex" className="logo" />
                                </a>
                            </div>
                            <div className="col-6 col-md-2 logo-container">
                                <a href="https://de.tradingview.com/pricing/?share_your_love=N-Schmidt" target="_blank" rel="noopener noreferrer">
                                    <img src={TradingViewLogo} alt="TradingView" className="logo" />
                                </a>
                            </div>
                            <div className="col-6 col-md-2 logo-container">
                                <a href="https://www.myfxbook.com/" target="_blank" rel="noopener noreferrer">
                                    <img src={MyFxBookLogo} alt="MyFxBook" className="logo" />
                                </a>
                            </div>
                            <div className="col-6 col-md-2 logo-container">
                                <a href="https://share.bitget.com/u/XVMWHTJN" target="_blank" rel="noopener noreferrer">
                                    <img src={BitgetLogo} alt="Bitget" className="logo" />
                                </a>
                            </div>
                            <div className="col-6 col-md-2 logo-container">
                                <a href="https://trader.ftmo.com/?affiliates=YYNxwRLDsLXIeYAfNwmc" target="_blank" rel="noopener noreferrer">
                                    <img src={FTMOLogo} alt="FTMO" className="logo" />
                                </a>
                            </div>
                            <div className="col-6 col-md-2 logo-container">
                                <a href="https://www.thetradingpit.com/de/challenges-overview?ref=tradingonjourney" target="_blank" rel="noopener noreferrer">
                                    <img src={TheTradingPitLogo} alt="TheTradingPit" className="logo" />
                                </a>
                            </div>
                        </div>
                    </div>
                </Section>

                <TradingViewWidget />

                {/* ADVANTAGES */}
                <Section id="vorteile">
                    <div className="container text-start">
                        <h4 className="page-section-title">Vorteile</h4>
                        <p className="page-section-description">Das Lifetime-Mentoring ist der ultimative Schritt für jeden Einsteiger oder bereits profitablen Trader, um wirklich langfristig erfolgreich an der Börse zu sein.</p>
                        <div className="mt-5">
                            <div className="row gy-4">
                                <div className="col-12 col-md-4">
                                    <div className="feature-box">
                                        <div className="feature-number">01</div>
                                        <h3 className="feature-title">Lebenslange Mitgliedschaft</h3>
                                        <p className="feature-description">Du erhältst unbegrenzten Zugang zu allen Inhalten. Alles, was jemals neu zum Thema Trading hinzugefügt wird, steht dir ohne weitere Gebühren zur Verfügung. Du wirst ein großer Teil der Trading On Journey Familie sein, und ich werde dich solange unterstützen, bis du profitabel an der Börse handeln kannst.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="feature-box">
                                        <div className="feature-number">02</div>
                                        <h3 className="feature-title">⁠Persönliche Live Calls + Betreuung</h3>
                                        <p className="feature-description">Es bietet dir zusätzliche Unterstützung in verschiedenen Bereichen. Ich stehe dir immer zur Seite, um deine Fragen zu beantworten und gemeinsam mit dir an deinem Trading-Erfolg zu arbeiten.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="feature-box">
                                        <div className="feature-number">03</div>
                                        <h3 className="feature-title">Wöchentliche Gruppen Live-Calls</h3>
                                        <p className="feature-description">Dort decken wir verschiedene Themen ab, darunter Live-Trading, theoretische Lektionen, Mindset-Calls, Hausaufgaben und mehr. Auf diese Weise stelle ich sicher, dass du ein umfassendes Verständnis für alle Trading-Aspekte entwickelst und sicher beherrschst.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="feature-box">
                                        <div className="feature-number">04</div>
                                        <h3 className="feature-title">Mehrstündiger Videokurs zur Trading Ausbildung</h3>
                                        <p className="feature-description">Erhalte regelmäßig neue Videos, die dir helfen, dich unabhängig von unseren Calls weiterzubilden. Zu Beginn wirst du aber nur einen kleinen Teil der Videos erhalten, da wir in der Anfangsphase vermehrt Live-Calls durchführen werden.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="feature-box">
                                        <div className="feature-number">05</div>
                                        <h3 className="feature-title">Flexibilität</h3>
                                        <p className="feature-description">Das Lifetime-Mentoring findet komplett online statt, damit du flexibel an Live-Calls teilnehmen oder die Videos in deinem eigenen Tempo ansehen kannst. Außerdem werden die Live-Calls für dich aufgezeichnet, damit du keine wichtigen Informationen verpasst.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="feature-box">
                                        <div className="feature-number">06</div>
                                        <h3 className="feature-title">⁠Exclusiven Discord Server</h3>
                                        <p className="feature-description">Hier erhältst du die Möglichkeit, Fragen zu stellen, Trades zu diskutieren und deine Fortschritte direkt mit mir zu teilen. Lerne andere Teilnehmer des Lifetime-Mentorings kennen, um euch zu unterstützen und wertvolle Erfahrungen auszutauschen.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>

                {/* RATINGS */}
                {/*<Section className="rating-background" id="bewertungen">
                    <div className="container text-start mt-5">
                        <h4 className="page-section-title">Bewertungen</h4>
                        <p className="page-section-description">Lorem ipsum dolor sit amet consectetur. Tempus tincidunt etiam eget elit id imperdiet et. Cras eu sit dignissim lorem nibh et. Ac cum eget habitasse in velit fringilla feugiat senectus in.</p>
                        <div className="mt-5">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="rating-box">
                                        <p className="rating-description">The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                        <div className="d-flex align-items-center mt-4">
                                            <img className="rating-user-avatar me-3" src={DefaultUserAvatar} alt="User Avatar" />
                                            <p className="rating-username">Tobias S.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="rating-box">
                                        <p className="rating-description">The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                        <div className="d-flex align-items-center mt-4">
                                            <img className="rating-user-avatar me-3" src={DefaultUserAvatar} alt="User Avatar" />
                                            <p className="rating-username">Tobias S.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="rating-box">
                                        <p className="rating-description">The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                        <div className="d-flex align-items-center mt-4">
                                            <img className="rating-user-avatar me-3" src={DefaultUserAvatar} alt="User Avatar" />
                                            <p className="rating-username">Tobias S.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="rating-box">
                                        <p className="rating-description">The web design course provided a solid foundation for me. The instructors were knowledgeable and supportive, and the interactive learning environment was engaging. I highly recommend it!</p>
                                        <div className="d-flex align-items-center mt-4">
                                            <img className="rating-user-avatar me-3" src={DefaultUserAvatar} alt="User Avatar" />
                                            <p className="rating-username">Tobias S.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>*/}

                {/* PRICES */}
                <Section id="details">
                    <div className="container text-start mt-5 mb-5">
                        <h4 className="page-section-title">Paketdetails</h4>
                        <p className="page-section-description"><b>Kurz vorab:</b> Das Lifetime-Mentoring-Programm ist nicht einfach so käuflich zu erwerben. Stattdessen hast du die Möglichkeit, dich unten über ein Bewerbungsformular anzumelden und ein kurzes Gespräch mit mir zu führen. So können wir uns kurz kennenlernen und schauen, ob eine gemeinsame Zusammenarbeit für uns beide passt.</p>
                        <div className="mt-5">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-5">
                                    <div className="pricing-box">
                                        <h2 className="pricing-title mt-4 mb-5">Lifetime - Mentoring</h2>
                                        {/*<p className="pricing-cost">3.500,00 €</p>
                                        <p className="pricing-quantity">einmalig</p>
                                        */}
                                        <p className="benefits-title">Du erhältst</p>
                                        <ul className="pricing-benefits">
                                            <li>Lebenslange Mitgliedschaft</li>
                                            <li>Persönliche Live Calls + Betreuung</li>
                                            <li>Wöchentliche Gruppen Live-Calls</li>
                                            <li>Mehrstündiger Videokurs zur Trading Ausbildung</li>
                                            <li>⁠Exclusiven Discord Server</li>
                                        </ul>
                                        {/*<p className="pricing-info"><b>Wichtig:</b> Sofern du dich beworben hast, erhältst du am 24.03.2024 per E-Mail die zu oder absage.</p>*/}
                                        <CustomButton
                                            className="w-100"
                                            color={'green'}
                                            title={user?.application ? `Beworben am ${formatDate(user.application.created_at)}` : 'Jetzt Bewerben'}
                                            onClick={onClickApply}
                                        />
                                    </div>
                                </div>
                                {/*<div className="col-12 col-md-5">
                                    <div className="pricing-box">
                                        <h2 className="pricing-title">VIP - Member</h2>
                                        <p className="pricing-cost">1.500,00 €</p>
                                        <p className="benefits-title">Du erhältst</p>
                                        <ul className="pricing-benefits">
                                            <li>Access to selected free courses.</li>
                                            <li>Limited course materials and resources.</li>
                                            <li>Limited course materials and resources.</li>
                                            <li>Access to exclusive Pro Plan community forums.</li>
                                            <li>Access to exclusive Pro Plan community forums.</li>
                                        </ul>
                                        <CustomButton className="w-100" color={'green'} title={'Jetzt Kaufen'} onClick={() => { }} />
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </Section>

                {/* FAQ */}
                <Section id="faq">
                    <FAQSection showMoreButton showAllQuestions={false} />
                </Section>

            </div>

            {isLoaded && (
                <PopupWidget
                    url="https://calendly.com/tradingonjourney/beratungsgespraech"
                    rootElement={document.getElementById("home-page")!}
                    text="Beratungsgespräch buchen"
                    textColor="#ffffff"
                    color="#56A476"
                    pageSettings={{
                        backgroundColor: '0E1225',
                        hideEventTypeDetails: false,
                        hideLandingPageDetails: false,
                        primaryColor: '56A476',
                        textColor: 'E4E4E7'
                    }}
                />
            )}
        </div>
    );
};

export default HomePage;

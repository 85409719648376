import FAQSection from "../../components/faq-section/faq-section";

// FAQPage.tsx
const FAQPage = () => {
    return (
        <div className="page-container">
            <div className="container mt-5 mb-5">
               <FAQSection showAllQuestions showMoreButton={false} />
            </div>
        </div>
    );
};

export default FAQPage;

// LoginPage.tsx
import { useEffect, useState } from "react";
//import Reputation from "../../components/reputations/reputation";
import Login from "./components/login";
import Signup from "./components/signup";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { getUser, registerUser } from "../../services/userService";
import useUserStore from "../../stores/userStore";
import useAuthStore from "../../stores/authStore";

import "./login-page.css";
import CustomLoading from "../../components/custom-loading/custom-loading";

const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Stores
    const { firebaseUser, setFirebaseUser } = useAuthStore();
    const { user, setUser } = useUserStore();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSignup, setShowSignup] = useState<boolean>(false);
    const [showEmailSent, setShowEmailSent] = useState<string | null>(null);

    useEffect(() => {
        if (!isLoading) {
            const checkForLogin = async () => {
                setIsLoading(true);

                const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;
                console.log('currentUrl: ' + currentUrl);

                try {
                    console.log('check is valid email..');
                    if (isSignInWithEmailLink(auth, currentUrl)) {
                        const email = localStorage.getItem('email');
                        if (email) {
                            console.log('try to login with email link...');
                            const credentials = await signInWithEmailLink(auth, email, currentUrl);
                            setFirebaseUser(credentials.user);

                            const token = await credentials.user.getIdToken();
                            console.log('perform server requests...');
                            const firstname = localStorage.getItem('firstname');
                            const lastname = localStorage.getItem('lastname');
                            if (firstname && lastname) {
                                const user = await registerUser(token, credentials.user.uid, email, firstname, lastname);
                                setUser(user);
                            } else {
                                const user = await getUser(token, credentials.user.uid);
                                setUser(user);
                            }
                            localStorage.removeItem("email");
                            localStorage.removeItem("firstname");
                            localStorage.removeItem("lastname");
                        } else {
                            console.log('Error while logging in because email missing.');
                        }
                    }
                } catch (error) {
                    console.log('error while performing logging:', error);
                } finally {
                    setIsLoading(false);
                }
            }
            checkForLogin();
        }
    }, [location, isLoading]);

    useEffect(() => {
        if (firebaseUser && user) {
            navigate("/application");
        } else if (firebaseUser && !user) {
            setIsLoading(true);
        }
    }, [firebaseUser, user]);

    return (
        <div className="page-container">
            {isLoading ? (
                <div className="container mt-5 mb-5">
                    <CustomLoading style={{ minHeight: "40vh" }} />
                </div>
            ) : (
                showEmailSent ? (
                    <div className="container mt-5 mb-5" style={{ minHeight: '40vh' }}>
                        <h3 className="login-email-sent-title text-center">Überprüfe dein E-Mail Postfach</h3>
                        <p className="login-email-sent-message text-center">Wir haben dir einen Verifizierungslink an <strong>{showEmailSent}</strong> gesendet.<br></br>Bitte prüfe deinen Posteingang und klicke auf den Link, um mit der Anmeldung fortzufahren.</p>
                    </div>
                ) : (
                    <div className="container mt-5 mb-5">
                        <div className="row justify-content-center align-items-center">
                            {/*<div className="col-12 col-md-5">
                                <Reputation />
                            </div>*/}
                            <div className="col-12 col-md-5">
                                {showSignup ? (
                                    <Signup
                                        goBackToLogin={() => setShowSignup(false)}
                                        onLoginEmailSent={(email: string) => setShowEmailSent(email)}
                                    />
                                ) : (
                                    <Login
                                        goToSignup={() => setShowSignup(true)}
                                        onLoginEmailSent={(email: string) => setShowEmailSent(email)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default LoginPage;

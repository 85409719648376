// BlogCard.tsx
import React from 'react';
import { formatDate } from '../../../utils/helpers';

import './blog-card.css';

type BlogCardProps = {
    id: number;
    image: string;
    title: string;
    readTime: number;
    description: string;
    createdAt: string;
    onClick: () => void;
};

const BlogCard: React.FC<BlogCardProps> = ({ id, image, title, readTime, description, createdAt, onClick }) => {
    return (
        <div className="blog-card" onClick={onClick} key={id}>
            <img src={image} alt="Blog Picture" className="blog-card-image" />
            <h5 className="blog-card-title mt-3">{title}</h5>
            <p className="blog-card-description">{description}</p>
            <p className="blog-card-date mb-2">Geschätzte Lesezeit: {readTime} Minuten</p>
            <p className="blog-card-date">Veröffentlicht am: {formatDate(createdAt)}</p>
        </div>
    );
};

export default BlogCard;

import React from 'react';
import { IoLogoInstagram } from 'react-icons/io';
import { IoLogoYoutube } from 'react-icons/io';
import Logo from '../../assets/images/logo-transparent.webp';
import styles from './Footer.module.css';
import { useNavigate } from 'react-router-dom';

const Footer: React.FC = () => {
    const navigate = useNavigate();

    return (
        <footer className={styles.footerContainer}>
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className={`col-12 col-md-6 ${styles.columnContent}`}>
                        <img src={Logo} width={100} alt="Logo" />
                        <a href="mailto:business@tradingonjourney.com" className={styles.footerText}>E-Mail: business@tradingonjourney.com</a>
                    </div>
                    <div className={`col-4 col-md-2 ${styles.columnContent}`}>
                        <strong className={styles.footerTitle}>Allgemeines</strong>
                        <button className={styles.linkButton} onClick={() => navigate("/contact")}>
                            Kontakt
                        </button>
                        <button className={styles.linkButton} onClick={() => navigate("/faq")}>
                            FAQ
                        </button>
                    </div>
                    <div className={`col-4 col-md-2 ${styles.columnContent}`}>
                        <strong className={styles.footerTitle}>Rechtliches</strong>
                        <button className={styles.linkButton} onClick={() => navigate("/imprint")}>
                            Impressum
                        </button>
                        <button className={styles.linkButton} onClick={() => navigate("/risk-disclaimer")}>
                            Risikohinweis
                        </button>
                        <button className={styles.linkButton} onClick={() => navigate("/privacy-policy")}>
                            Datenschutzbedingungen
                        </button>
                        <button className={styles.linkButton} onClick={() => navigate("/agb")}>
                            Allgemeine Geschäftsbedingungen
                        </button>
                    </div>
                    <div className={`col-4 col-md-2 ${styles.columnContent}`}>
                        <strong className={styles.footerTitle}>Social Media</strong>
                        <div className={styles.socialMedia}>
                            <a href="https://www.instagram.com/tradingonjourney" target="_blank" rel="noopener noreferrer"><IoLogoInstagram /></a>
                            <a href="https://www.youtube.com/@tradingonjourney" target="_blank" rel="noopener noreferrer"><IoLogoYoutube /></a>
                        </div>
                    </div>
                </div>
                <div className="mt-5" style={{ height: 1, backgroundColor: "rgba(255,255,255,0.1)" }} />
                <div className="row mt-4 mb-5">
                    <div className="col-12 text-center">
                        <small>© 2024 TradingOnJourney. Alle Rechte vorbehalten.</small>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

// ImprintPage.tsx
import "./imprint-page.css";

const ImprintPage = () => {
    return (
        <div className="page-container">
            <div className="container mt-5 mb-5 imprint">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h1 className="title">Impressum</h1>
                    </div>
                    <div className="col-12 col-md-6">
                        <p className="description">Auf dieser Seite findest du alle rechtlichen Informationen zu TradingOnJourney, einem in Dubai ansässigen Unternehmen, das digitale Trading-Ausbildungen für Kunden in Deutschland anbietet.</p>
                    </div>
                </div>
                <div className="mt-4 mb-5" style={{ height: 1, backgroundColor: "rgba(255,255,255,0.1)" }} />
                <h3>Angaben gemäß § 5 TMG:</h3>
                <p>• Bitpalm Computer Systems & Communication
                    Equipment Software Design LLC</p>
                <p>• Limited Liability Company</p>
                <p>• Registrierter Sitz: Moaza Khalfan Building, Al Mararr, Dubai, UAE</p>
                <h3>Kontakt:</h3>
                <p>E-Mail: business@tradingonjourney.com</p>
                <h3>Umsatzsteuer-ID:</h3>
                <p>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: 104148282700003</p>
                <h3>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</h3>
                <p>Niklas Schmidt, Dubai, UAE</p>
                <p><strong>"TradingOnJourney"</strong> ist eine Marke der Bitpalm Computer Systems & Communication
                    Equipment Software Design LLC.</p>
                <h3>Haftungsausschluss:</h3>
                <p>Die Bitpalm Computer Systems & Communication
                    Equipment Software Design LLC übernimmt keine Haftung für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen die Bitpalm Computer Systems & Communication
                    Equipment Software Design LLC, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens der Bitpalm Computer Systems & Communication
                    Equipment Software Design LLC kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.</p>
                <h3>Urheberrecht:</h3>
                <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
                <h3>Datenschutz:</h3>
                <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
                <h3>Hinweis zur Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO:</h3>
                <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a> finden.</p>
            </div>
        </div>
    );
};

export default ImprintPage;

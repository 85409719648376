// Login.tsx
import React, { useState } from 'react';
import CustomButton from '../../../components/custom-button/custom-button';
import GoogleButton from '../../../components/google-button/google-button';
import { auth } from "../../../firebase";
import { sendSignInLinkToEmail } from 'firebase/auth';
import { isEmailRegistered } from '../../../services/authService';
import { isValidEmail } from '../../../utils/helpers';

import './login.css';

interface LoginProps {
    goToSignup: () => void;
    onLoginEmailSent: (email: string) => void;
}

const Login: React.FC<LoginProps> = ({ goToSignup, onLoginEmailSent }) => {
    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<string | null>(null);
    const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);

    const handleCheckboxChange = () => {
        setCheckboxChecked(!checkboxChecked);
    };

    const onClickLogin = async () => {
        if (!checkboxChecked) {
            alert("Du musst unsere Allgemeinen Geschäftbedingungen und Datenschutzbedingungen akzeptieren.");
            return;
        }

        if (email) {
            if (isValidEmail(email)) {
                try {
                    setIsLoading(true);
                    setEmailError(null);

                    const existEmail: boolean = await isEmailRegistered(email);
                    if (existEmail) {
                        const actionCodeSettings = {
                            url: `${window.location.origin}/login`,
                            handleCodeInApp: true,
                        };
                        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
                        localStorage.setItem('email', email);
                        onLoginEmailSent(email);
                    } else {
                        setEmailError("Die E-Mail ist nicht registriert.");
                    }
                } catch (error) {
                    console.log('error while sign in over email:', error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setEmailError("Tippe eine gültige E-Mail ein.");
            }
        }
    }

    return (
        <div className="login">
            <h2 className="title">Willkommen!</h2>
            <p className="description">Melde dich an, um auf dein Konto zuzugreifen.</p>
            <div className="text-start">
                <label className="text-input-label form-check-label mb-2">E-Mail</label>
                <input
                    type="email"
                    className="form-control text-input"
                    placeholder="niklas@tradingonjourney.com"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setEmail(e.target.value);
                        setEmailError(null);
                    }}
                />
            </div>
            {emailError && (
                <p className="email-error mt-2 ms-2 mb-0 text-start">{emailError}</p>
            )}
            <div className="mt-3 form-check text-start">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="agbDatenschutzCheckbox"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                />
                <label className="login-terms-check-label" htmlFor="agbDatenschutzCheckbox">
                    Ich akzeptiere die <a href="/agb" target="_blan">AGB</a> und die <a href="/privacy-policy">Datenschutzbestimmungen</a>
                </label>
            </div>
            <CustomButton
                className="w-100 mb-4 mt-4"
                color="green"
                title="Anmelden"
                onClick={onClickLogin}
                loading={isLoading}
            />
            <div className="d-flex align-items-center mb-3">
                <hr className="flex-grow-1" />
                <span className="mx-2">ODER</span>
                <hr className="flex-grow-1" />
            </div>
            <GoogleButton className="w-100 mb-4" loading={isLoading} />
            <p className="signup">Noch kein Konto? <a style={{ cursor: "pointer" }} className="text-light" onClick={goToSignup}>Kostenlos erstellen!</a></p>
        </div >
    );
};

export default Login;

import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Root from './routes/root';
import ErrorPage from './error-page';
import HomePage from './routes/home-page/home-page';
import LoginPage from './routes/login/login-page';
import ProtectedRoute from './routes/protected-route';
//import CoursesPage from './routes/courses/courses-page';
//import CoursePage from './routes/course/course-page';
import ImprintPage from './routes/imprint/imprint-page';
import ScrollToTop from './routes/scroll-to-top';
import FAQPage from './routes/faq/faq-page';
import AboutMePage from './routes/about-me/about-me-page';
import ApplicationPage from './routes/application/application-page';
import RiskDisclaimerPage from './routes/risk-disclaimer/risk-disclaimer-page';
import ReactGA from 'react-ga4';
import PrivacyPolicyPage from './routes/privacy-policy/privacy-policy-page';
import AGBPage from './routes/agb/agb-page';
import ContactPage from './routes/contact/contact-page';
import BlogPage from './routes/blog/blog-page';
import BlogPostPage from './routes/blog-post/blog-post-page';

ReactGA.initialize('G-MDL99S6LY9');

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <>
          <ScrollToTop />
          <HomePage />
        </>,
      },
      {
        path: "/login",
        element: <>
          <ScrollToTop />
          <LoginPage />
        </>,
      },
      {
        path: "/contact",
        element: <>
          <ScrollToTop />
          <ContactPage />
        </>,
      },
      {
        path: "/imprint",
        element: <>
          <ScrollToTop />
          <ImprintPage />
        </>,
      },
      {
        path: "/risk-disclaimer",
        element: <>
          <ScrollToTop />
          <RiskDisclaimerPage />
        </>,
      },
      {
        path: "/privacy-policy",
        element: <>
          <ScrollToTop />
          <PrivacyPolicyPage />
        </>,
      },
      {
        path: "/agb",
        element: <>
          <ScrollToTop />
          <AGBPage />
        </>,
      },
      {
        path: "/faq",
        element: <>
          <ScrollToTop />
          <FAQPage />
        </>,
      },
      {
        path: "/about-me",
        element: <>
          <ScrollToTop />
          <AboutMePage />
        </>,
      },
      {
        path: "/blog",
        element: <>
          <ScrollToTop />
          <BlogPage />
        </>,
      },
      {
        path: "/blog/:id",
        element: <>
          <ScrollToTop />
          <BlogPostPage />
        </>,
      },
      {
        path: "/application",
        element: <>
          <ProtectedRoute>
            <ScrollToTop />
            <ApplicationPage />
          </ProtectedRoute>
        </>,
      },
      /*{
        path: "/courses",
        element: <>
          <ProtectedRoute>
            <ScrollToTop />
            <CoursesPage />
          </ProtectedRoute>
        </>,
      },
      {
        path: "/course",
        element: <>
          <ProtectedRoute>
            <ScrollToTop />
            <CoursePage />
          </ProtectedRoute>
        </>,
      },*/
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useRef, useState } from 'react';
import styles from './Section.module.css';

type SectionProps = {
    id?: string;
    className?: string | undefined;
    children: React.ReactNode;
};

const Section: React.FC<SectionProps> = ({ id, className, children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef<HTMLDivElement>(null);

    let observer: IntersectionObserver;

    useEffect(() => {
        if (sectionRef.current) {
            observer = new IntersectionObserver(
                (entries) => {
                    const [entry] = entries;
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                },
                {
                    root: null,
                    threshold: 0.1,
                    rootMargin: '0px 0px -10%'
                }
            );

            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <section
            id={id}
            ref={sectionRef}
            className={`${className} ${styles.section} ${isVisible ? styles.isVisible : ''}`}
        >
            {children}
        </section>
    );
};

export default Section;

// RiskDisclaimerPage.tsx
import "./risk-disclaimer-page.css";

const RiskDisclaimerPage = () => {
    return (
        <div className="page-container">
            <div className="container mt-5 mb-5 risk-disclaimer">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h1 className="title">Risikohinweis</h1>
                    </div>
                </div>
                <div className="mt-4 mb-5" style={{ height: 1, backgroundColor: "rgba(255,255,255,0.1)" }} />
                <h3 className="mb-4">Allgemeiner Risikohinweis:</h3>
                <p>Der Handel mit Finanzinstrumenten und/oder Kryptowährungen birgt ein hohes Risiko, einschließlich des Risikos, einen erheblichen Teil Ihrer Investition zu verlieren, oder sogar mehr als Ihre anfängliche Investition in einigen Fällen. Finanzmärkte sind volatil und können sich schnell bewegen, was bedeutet, dass Ihre Verluste schnell und bedeutend sein können.</p>

                <p>Bitte beachten Sie, dass die auf dieser Webseite angebotenen Informationen, Kurse, Mentorings und Handelssignale keine finanzielle Beratung darstellen und nicht als solche interpretiert werden sollten. Sie dienen lediglich Bildungszwecken und sollen Ihre eigenen Handelsentscheidungen nicht ersetzen. Jede Entscheidung, Finanzinstrumente zu handeln, liegt in Ihrer eigenen Verantwortung, und Sie sollten nur handeln, wenn Sie die damit verbundenen Risiken vollständig verstehen.</p>

                <p>Es wird empfohlen, dass Sie sich unabhängig beraten lassen und/oder ausreichend recherchieren und sich selbst über die Risiken des Handels an den Finanzmärkten informieren, bevor Sie mit dem Handel beginnen. Diese Webseite übernimmt keine Haftung für direkte, indirekte oder folgende Verluste, die aus dem Handel mit Finanzinstrumenten oder -produkten entstehen, die auf dieser Webseite besprochen oder empfohlen werden.</p>

                <p>Die historische Performance von Handelsstrategien oder -signalen ist keine Garantie für zukünftige Ergebnisse. Investitionen in Finanzmärkte sollten als spekulativ betrachtet werden und Sie sollten nur Kapital investieren, dessen Verlust Sie sich leisten können.</p>
                <h3 className="mt-5 mb-4">Risikohinweis zum Lifetime-Mentoring im Trading:</h3>
                <p><strong>Ergänzung zum Allgemeinen Risikohinweis</strong></p>
                <p>Bitte beachten Sie, dass zusätzlich zu den nachfolgenden spezifischen Risikohinweisen zum Lifetime-Mentoring die in unserem allgemeinen Risikohinweis dargelegten Informationen und Warnungen ebenfalls Gültigkeit haben. Es wird empfohlen, beide Hinweise sorgfältig zu lesen und zu berücksichtigen.</p>

                <p><strong>Spezifische Risiken des Lifetime-Mentorings</strong></p>
                <p>Das Lifetime-Mentoring Programm bei Trading On Journey zielt darauf ab, tiefgreifende Ausbildungs- und Informationsdienstleistungen im Bereich Trading anzubieten. Dieses Programm ergänzt die allgemeinen Risiken des Tradings mit spezifischen Herausforderungen und Nuancen, die im Rahmen eines solchen Mentorings auftreten können. Zu diesen gehören Marktvolatilität, Hebelwirkung, und das persönliche Risikoprofil eines jeden Teilnehmers.</p>

                <p><strong>Bildungscharakter und Selbstverantwortung</strong></p>
                <p>Die Inhalte unseres Lifetime-Mentorings, seien es Gruppenanrufe oder individuelle Lerninhalte, dienen ausschließlich Bildungszwecken. Sie sollen Ihr Verständnis und Ihre Fähigkeiten im Trading stärken und sind nicht als direkte Handelsempfehlungen oder finanzielle Beratung zu verstehen. Jede Handelsentscheidung, die auf Basis dieser Inhalte getroffen wird, sollte gut durchdacht sein und im Rahmen einer eigenständigen Risikobewertung erfolgen.</p>

                <p><strong>Keine Erfolgsgarantie</strong></p>
                <p>Trotz der Vermittlung von Fachwissen und Erfahrungen im Rahmen unseres Mentorings kann der Erfolg im Trading nicht garantiert werden. Historische Erfolge oder Beispiele aus der Vergangenheit bilden keine zuverlässige Grundlage für zukünftige Ergebnisse. Jeder Markt ist einzigartig und kann durch vielfältige externe Faktoren beeinflusst werden.</p>

                <p><strong>Weiterführende unabhängige Beratung</strong></p>
                <p>Wir raten allen Teilnehmern, eine aktive und selbstkritische Rolle in ihrem Lernprozess einzunehmen und zusätzliche unabhängige Beratungen einzuholen, um ein umfassendes Verständnis aktueller Marktbedingungen und rechtlicher Aspekte des Tradings zu gewährleisten.</p>

                <p><strong>Steuerliche Betrachtungen</strong></p>
                <p>Zudem sollten Sie sich der steuerlichen Konsequenzen Ihrer Handelsgeschäfte bewusst sein. Es wird empfohlen, bei Bedarf fachkundige steuerliche Beratung in Ihrem Wohnsitzland in Anspruch zu nehmen.</p>

                <p>Trading On Journey haftet nicht für Verluste oder Schäden, die aus Handelsaktivitäten entstehen, die auf Grundlage der im Mentoring vermittelten Kenntnisse durchgeführt werden.</p>
                <h3 className="mt-5 mb-4">Risikohinweis zu Signaldienste:</h3>
                <p>Unser Signaldienst bietet lediglich Einblicke in unsere Handelsaktivitäten. Es ist wichtig zu verstehen, dass wir keine Anlageberatung oder Handelsempfehlungen geben.</p>

                <p>Die Entscheidung, unsere Handelspositionen zu adaptieren, liegt vollständig in der Hand des Nutzers und wird von uns nicht beeinflusst. Wir möchten betonen, dass jeder Handel mit Risiken verbunden ist und die Verantwortung für jegliche Handelsentscheidungen und deren Ergebnisse ausschließlich beim Nutzer liegt.</p>

                <p>Die Vervielfältigung oder Weitergabe unserer Handelspositionen, auch bekannt als Signale, ist nicht gestattet. Nutzer können diese Signale auf eigenes Risiko in ihren Handelskonten bei einem Broker ihrer Wahl umsetzen.</p>

                <p>Wir übernehmen keine Haftung für mögliche Verluste oder Gewinne, die aus der Nutzung unserer Signale resultieren.</p>
            </div>
        </div>
    );
};

export default RiskDisclaimerPage;

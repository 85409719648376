import { User } from "firebase/auth";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface AuthState {
  firebaseUser: User | null;
  setFirebaseUser: (firebaseUser: User | null) => void;
}

const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        firebaseUser: null,
        setFirebaseUser: (user: User | null) => set({ firebaseUser: user }),
      }),
      {
        name: "auth-storage",
      }
    )
  )
);

export default useAuthStore;

// PrivacyPolicyPage.tsx
import "./privacy-policy-page.css";

const PrivacyPolicyPage = () => {
    return (
        <div className="page-container">
            <div className="container mt-5 mb-5 privacy-policy">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h1 className="title">Datenschutzbedingungen</h1>
                    </div>
                </div>
                <div className="mt-4 mb-5" style={{ height: 1, backgroundColor: "rgba(255,255,255,0.1)" }} />
                <p>
                    Wir, Trading On Journey, eine Marke von Bitpalm Computer Systems & Communication
                    Equipment Software Design LLC, nehmen den Schutz deiner persönlichen Daten sehr ernst.
                    Nachfolgend möchten wir dich darüber informieren, welche Daten wir von dir erfassen und wie wir diese verwenden:
                </p>

                <h3>Erfasste Daten:</h3>
                <ul>
                    <li>Vorname und Nachname</li>
                    <li>E-Mail-Adresse</li>
                </ul>

                <h3>Verwendungszweck:</h3>
                <p>
                    Wir verwenden deinen Vor- und Nachnamen zur Personalisierung unserer Dienste.
                    Dies ermöglicht es uns beispielsweise, dich persönlich zu begrüßen, wenn du dich auf unserer Website anmeldest.
                </p>

                <h3>Weitergabe an Dritte:</h3>
                <p>
                    Wir geben deine Daten nicht an Dritte weiter, mit Ausnahme von Google Analytics zur Analyse von Website-Besuchen.
                    Bitte beachte die <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Datenschutzbestimmungen von Google Analytics</a> für weitere Informationen.
                </p>

                <h3>Datensicherheit:</h3>
                <p>
                    Deine Daten sind bei uns sicher. Unsere API ist geschützt, und nur du hast Zugriff auf deine persönlichen Daten.
                </p>

                <h3>Speicherdauer:</h3>
                <p>
                    Deine Daten werden so lange gespeichert, bis du dein Konto löschst.
                </p>

                <h3>Deine Rechte:</h3>
                <p>
                    Gemäß der Datenschutz-Grundverordnung (DSGVO) hast du das Recht auf Zugriff, Berichtigung, Löschung und Widerspruch in Bezug auf deine personenbezogenen Daten.
                    Bitte kontaktiere uns, wenn du von einem dieser Rechte Gebrauch machen möchtest.
                </p>

                <p>
                    Wenn du Fragen zu unseren Datenschutzbedingungen hast oder weitere Informationen benötigst, kontaktiere uns bitte unter business@tradingonjourney.com.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;

import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export interface IBlog {
  id: number;
  picture: string;
  title: string;
  description: string;
  text?: string;
  views: number;
  read_time: number;
  created_at: string;
  updated_at: string;
}

export interface IBlogComment {
  id: number;
  user_id: string;
  firstname: string;
  lastname?: string;
  comment_text: string;
  created_at: string;
  updated_at: string;
}

interface BlogState {
  blogs: IBlog[];
  selectedBlog: IBlog | null;
  comments: IBlogComment[];
  setBlogs: (blogs: IBlog[]) => void;
  setSelectedBlog: (blog: IBlog | null) => void;
  setComments: (comments: IBlogComment[]) => void;
}

const useBlogStore = create<BlogState>()(
  devtools(
    persist(
      (set) => ({
        blogs: [],
        selectedBlog: null,
        comments: [],
        setBlogs: (blogs: IBlog[]) => set({ blogs }),
        setSelectedBlog: (blog: IBlog | null) => set({ selectedBlog: blog }),
        setComments: (comments: IBlogComment[]) => set({ comments }),
      }),
      {
        name: "blog-storage",
      }
    )
  )
);

export default useBlogStore;

import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import CustomButton from "../custom-button/custom-button";
import { useLocation, useNavigate } from "react-router-dom";
import useUserStore from "../../stores/userStore";
import useAuthStore from "../../stores/authStore";
import { auth } from "../../firebase";

import './topbar.css';
import { DISCORD_URL } from "../../utils/constants";

const TopBar: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Stores
    const { setFirebaseUser } = useAuthStore();
    const { user, setUser } = useUserStore();

    // States
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 75);
        };

        // Add the event listener
        window.addEventListener('scroll', handleScroll);

        // Remove the event listener on cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onClickLogout = async () => {
        await auth.signOut();

        setUser(null);
        setFirebaseUser(null);
    }

    return (
        <Navbar className={isSticky ? "sticky-shadow" : ""} expand="lg" sticky="top">
            <CustomButton className="me-3" color="white" title="Home" onClick={() => {
                navigate("/");
            }} />
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    {location.pathname === "/" && (
                        <>
                            <Nav.Link style={{ cursor: "pointer" }} onClick={() => {
                                navigate("/about-me");
                            }}>Über mich</Nav.Link>
                            <Nav.Link href="#vorteile">Vorteile</Nav.Link>
                            {/*<Nav.Link href="#bewertungen">Bewertungen</Nav.Link>*/}
                            <Nav.Link href="#details">Paketdetails</Nav.Link>
                            <Nav.Link href="#faq">FAQ</Nav.Link>
                            <Nav.Link style={{ cursor: "pointer" }} onClick={() => {
                                navigate("/blog");
                            }}>Blog</Nav.Link>
                        </>
                    )}
                    {/*<Nav.Link href="#link6">Kontakt</Nav.Link>*/}
                    <Nav.Link style={{ color: "#56A476" }} href={DISCORD_URL} target="_blank" rel="noopener noreferrer">Join Discord!</Nav.Link>
                </Nav>
                {!location.pathname.includes("login") && (
                    <Nav>
                        {user ? (
                            <>
                                <Nav.Link className="me-2" onClick={() => navigate("/application")}>Willkomen, {user.firstname}!</Nav.Link>
                                <CustomButton color="red" title="Abmelden" onClick={onClickLogout} />
                            </>
                        ) : (
                            <CustomButton color="green" title="Anmelden" onClick={() => {
                                navigate("/login");
                            }} />
                        )}
                    </Nav>
                )}
            </Navbar.Collapse>
        </Navbar>
    );
};

export default TopBar;

// ContactPage.tsx
import { useState } from "react";
import CustomButton from "../../components/custom-button/custom-button";
import ContactPicture from "../../assets/images/contact-picture.png";

import "./contact-page.css";
import { sendContactForm } from "../../services/contactService";

const ContactPage = () => {
    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [emailError, setEmailError] = useState<string | null>(null);
    const [nameError, setNameError] = useState<string | null>(null);
    const [subjectError, setSubjectError] = useState<string | null>(null);
    const [messageError, setMessageError] = useState<string | null>(null);
    const [generalError, setGeneralError] = useState<string | null>(null);

    const clearErrors = () => {
        setEmailError(null);
        setNameError(null);
        setSubjectError(null);
        setMessageError(null);
        setGeneralError(null);
    }

    const onClickSend = async () => {
        let hasError = false;
        if (!name.trim()) {
            setNameError("Name ist erforderlich");
            hasError = true;
        }
        if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
            setEmailError("Keine gültige E-Mail");
            hasError = true;
        }
        if (!subject.trim()) {
            setSubjectError("Betreff ist erforderlich");
            hasError = true;
        }
        if (!message.trim()) {
            setMessageError("Nachricht ist erforderlich");
            hasError = true;
        }

        if (!hasError && !isLoading) {
            try {
                setIsLoading(true);
                clearErrors();

                const hasBeenSent = await sendContactForm({
                    name,
                    email,
                    subject,
                    message
                });

                setEmailSent(hasBeenSent);

                if (!hasBeenSent) {
                    setGeneralError("Deine Nachricht konnte nicht versendet werden, versuche es erneut.");
                }

            } catch (error) {
                console.log('Error while sending email:', error);
                setGeneralError("Es ist ein Fehler beim versenden deiner Nachricht aufgetreten.");
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="page-container">
            <div className="container mt-5 mb-5 contact">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <h1 className="title">Kontakt</h1>
                    </div>
                </div>

                <div className="mt-4 mb-5" style={{ height: 1, backgroundColor: "rgba(255,255,255,0.1)" }} />

                {emailSent ? (
                    <div style={{ height: "20vh" }}>
                        <h3 className="contact-email-sent-title">Nachricht versendet!</h3>
                        <p className="contact-email-sent-message">Deine Nachricht wurde erfolgreich gesendet. Wir werden uns schnellstmöglich bei dir melden.</p>
                    </div>
                ) : (
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 d-none d-md-block col-md-4">
                            <img className="contact-picture" src={ContactPicture} alt="Contact Picture" />
                        </div>
                        <div className="col-12 col-md-6 contact-box">
                            <div className="row gy-3">
                                <div className="col-12 col-md-6">
                                    <div className="text-start">
                                        <label className="text-input-label form-check-label mb-2">Name</label>
                                        <input
                                            type="text"
                                            className="form-control text-input"
                                            placeholder="Niklas"
                                            value={name}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setName(e.target.value);
                                                setNameError(null);
                                            }}
                                        />
                                    </div>
                                    {nameError && (
                                        <p className="error-text mt-2 ms-2 mb-0 text-start">{nameError}</p>
                                    )}
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="text-start">
                                        <label className="text-input-label form-check-label mb-2">E-Mail</label>
                                        <input
                                            type="email"
                                            className="form-control text-input"
                                            placeholder="niklas@tradingonjourney.com"
                                            value={email}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setEmail(e.target.value);
                                                setEmailError(null);
                                            }}
                                        />
                                    </div>
                                    {emailError && (
                                        <p className="error-text mt-2 ms-2 mb-0 text-start">{emailError}</p>
                                    )}
                                </div>
                                <div className="col-12">
                                    <div className="text-start">
                                        <label className="text-input-label form-check-label mb-2">Betreff</label>
                                        <input
                                            type="text"
                                            className="form-control text-input"
                                            placeholder="Tippe hier den Betreff ein..."
                                            value={subject}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setSubject(e.target.value);
                                                setSubjectError(null);
                                            }}
                                        />
                                    </div>
                                    {subjectError && (
                                        <p className="error-text mt-2 ms-2 mb-0 text-start">{subjectError}</p>
                                    )}
                                </div>
                                <div className="col-12">
                                    <div className="text-start">
                                        <label className="text-input-label form-check-label mb-2">Nachricht</label>
                                        <textarea
                                            className="form-control textarea-input"
                                            placeholder="Tippe hier deine Nachricht ein..."
                                            value={message}
                                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                setMessage(e.target.value);
                                                setMessageError(null);
                                            }}
                                        />
                                    </div>
                                    {messageError && (
                                        <p className="error-text mt-2 ms-2 mb-0 text-start">{messageError}</p>
                                    )}
                                </div>
                            </div>
                            <CustomButton
                                className="w-100 mt-4"
                                color="green"
                                title="Send Message"
                                onClick={onClickSend}
                                loading={isLoading}
                            />
                            {generalError && (
                                <p className="error-text mt-3 ms-2 mb-0 text-start">{generalError}</p>
                            )}
                        </div>
                    </div>
                )}
            </div >
        </div >
    );
};

export default ContactPage;

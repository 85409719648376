// AboutMePage.tsx
import NiklasImage from '../../assets/images/niklas.jpg';
import NiklasAustralien from '../../assets/images/niklas_australien.jpg';
import { Fade, Slide } from 'react-awesome-reveal';
import { IoLogoInstagram } from 'react-icons/io';

import "./about-me-page.css";

const AboutMePage = () => {
    return (
        <div className="page-container">
            <div className="container mt-5 mb-5 about-me">
                <div className="d-flex align-items-center">
                    <h1 className="title">Über Mich</h1>
                    <a className="instagram" href="https://www.instagram.com/_niklas10_" target="_blank" rel="noopener noreferrer"><IoLogoInstagram /></a>
                </div>
                <div className="mt-4 mb-5" style={{ height: 1, backgroundColor: "rgba(255,255,255,0.1)" }} />
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Fade triggerOnce>
                            <h3 className="mb-4">Ich bin Niklas, 26 Jahre alt und komme aus Deutschland.</h3>
                            <p>Im Jahr 2013 habe ich eine ganz normale Ausbildung als Kfz-Mechatroniker absolviert und wurde im Jahr 2017 zum ersten Mal auf den Handel an der Börse aufmerksam. Seitdem habe ich meine Leidenschaft für den Handel an den Finanzmärkten entdeckt.
                                <br></br><br></br>Im Jahr 2018 ging ich dann zusammen mit meiner Freundin für ein Jahr nach Australien, um Work and Travel zu machen. Dort wurde mir erstmals bewusst, wie spannend es ist, während des Reisens zu handeln und unabhängig Geld zu verdienen, egal wo man sich gerade auf der Welt befindet.
                                <br></br><br></br>Im Jahr 2019, nach meinem Jahr in Australien, habe ich mich noch stärker auf den Forex-Markt spezialisiert und konnte seit 2022 bis heute erfolgreich an der Börse handeln.
                                Dadurch habe ich mir ein finanzielles Polster aufgebaut, das es mir ermöglichte, im August 2023 Deutschland zu verlassen und nun mein Traumleben zu führen.</p>
                        </Fade>
                    </div>
                    <div className="col-12 col-md-6">
                        <Slide direction='right' triggerOnce>
                            <img src={NiklasImage} alt="Bild von Niklas"/>
                        </Slide>
                    </div>
                    <div className="col-12 col-md-6 mt-5">
                        <Slide direction='left' triggerOnce>
                            <img src={NiklasAustralien} alt="Bild von Niklas in Australien"/>
                        </Slide>
                    </div>
                    <div className="col-12 col-md-6 mt-5">
                        <Fade triggerOnce>
                            <h3 className="mb-4">Die Realität hinter dem Handel an der Börse: Zwischen Leidenschaft, Betrug und echtem Erfolg.</h3>
                            <p>Auf Social Media werde ich bis heute oft gefragt, wie ich es schaffe, von überall auf der Welt zu arbeiten, wie ich mein Geld verdiene und wie es mir gelungen ist, an der Börse erfolgreich zu sein. Viele bitten mich darum, ihnen zu zeigen, wie es funktioniert, oder ob ich es ihnen beibringen könnte. Ich betone jedoch immer, dass es ein Prozess ist, den man nicht über Nacht erlernen kann, und dass Trading echte Leidenschaft erfordert.
                                <br></br><br></br>Es ist auch wichtig zu erwähnen, dass das Thema Coaching und Ausbildungsverkauf für viele Menschen leider sofort mit Betrug, Affiliate-Programmen oder dem Verkauf irgendwelcher Signalgruppen in Verbindung gebracht wird, was ich auch aus eigener Erfahrung bestätigen kann.
                                <br></br><br></br>Das liegt aber auch daran, dass über die Jahre viele Betrüger und selbsternannte Gurus gekommen sind, die behaupten, sie könnten das Handeln an der Börse lehren, obwohl sie selbst kaum Erfahrung damit haben. Sie versuchen, Signalgruppen zu verkaufen oder Methoden zu zeigen, mit denen man angeblich über Nacht Tausende von Euros verdienen kann.
                                <br></br><br></br>Das große Problem dabei ist, dass die meisten von ihnen nicht mit echtem Geld handeln, sondern nur mit Cent-Konten oder Demo-Konten und damit in ihren Videos prahlen oder versuchen, Affiliate-Programme zu bewerben, von denen sie dann Provisionen erhalten.
                            </p>
                        </Fade>
                    </div>
                    <div className="col-12 mt-5">
                        <Fade triggerOnce>
                            <h3 className="mb-4">Meine Trading-Reise: Von der Leidenschaft zum Lebensstil.</h3>
                            <p>
                                Ich bin jedoch stolz darauf, sagen zu können, dass ich mein Einkommen ausschließlich durch den Börsenhandel erziele und dass ich durch echtes Trading die Möglichkeit hatte, nach Dubai auszuwandern.<br></br><br></br>
                                Lange habe ich darüber nachgedacht, ob ich mein Wissen teilen soll, und habe mich letztendlich doch dafür entschieden. Viele Nachrichten haben mich erreicht, von Menschen, die im Bereich des Tradings nicht weiterwussten und sogar den Glauben daran verloren haben.<br></br><br></br>
                                Ich habe bei verschiedensten Mentoren gelernt und habe mir aus all dem Wissen und der praktischen Erfahrung meinen eigenen Trading-Skill aufgebaut.
                                Daher habe ich Trading On Journey ins Leben gerufen. Der Name "Trading On Journey" spiegelt meine Leidenschaft wider, durch die Welt zu reisen und von überall aus arbeiten zu können.<br></br><br></br>
                                Mein Ziel ist es, dir mit Trading On Journey die Chance zu geben und dich Schritt für Schritt zu einem profitablen Vollzeit-Trader auszubilden. Mein echtes Interesse liegt darin, dir zu helfen, finanziell frei und unabhängig zu werden, und das möchte ich mit Trading On Journey erreichen!<br></br><br></br>
                                Ich freue mich darauf, dich kennenzulernen und gemeinsam an der Verwirklichung deiner Ziele zu arbeiten, indem ich dich auf deinem Weg zum Vollzeittrader unterstütze!
                            </p>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutMePage;

import { IApplication, IUser } from "../stores/userStore";
import { API_URL } from "../utils/constants";

export const registerUser = async (
  token: string,
  userId: string,
  email: string,
  firstname: string,
  lastname: string
): Promise<IUser | null> => {
  try {
    const response = await fetch(`${API_URL}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: userId,
        email,
        firstname,
        lastname,
      }),
    });

    if (response.status !== 201) return null;

    const user = await response.json();
    return user;
  } catch (error) {
    console.error("Error during the API call:", error);
    return null;
  }
};

export const getUser = async (
  token: string,
  userId: string
): Promise<IUser | null> => {
  try {
    const response = await fetch(`${API_URL}/user/${userId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200) return null;

    const user = await response.json();
    return user;
  } catch (error) {
    console.error("Error during the API call:", error);
    return null;
  }
};

export const createApplication = async (
  token: string,
  userId: string,
): Promise<IApplication | null> => {
  try {
    const response = await fetch(`${API_URL}/user/${userId}/application`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 201) return null;

    const application = await response.json();
    return application;
  } catch (error) {
    console.error("Error during the API call:", error);
    return null;
  }
};

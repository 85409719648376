export const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const extractNames = (displayName: string | null) => {
  if (!displayName) return { firstName: null, lastName: null };

  const names = displayName.trim().split(/\s+/);
  if (names.length === 1) {
    return { firstName: names[0], lastName: null };
  } else {
    const lastName = names.pop();
    const firstName = names.join(" ");
    return { firstName: firstName, lastName: lastName ?? null };
  }
};

export const formatDate = (input: string): string => {
  const date = new Date(input);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns 0-11
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export const calculateTimeAgo = (isoDate: string): string => {
  const sentTime = new Date(isoDate);
  const now = new Date();
  const deltaSeconds = Math.floor((now.getTime() - sentTime.getTime()) / 1000);

  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;

  if (deltaSeconds < minute) {
    return deltaSeconds < 10 ? "gerade eben" : `vor ${deltaSeconds} Sekunden`;
  } else if (deltaSeconds < hour) {
    const minutes = Math.floor(deltaSeconds / minute);
    return `vor ${minutes} Minute${minutes !== 1 ? "n" : ""}`;
  } else if (deltaSeconds < day) {
    const hours = Math.floor(deltaSeconds / hour);
    return `vor ${hours} Stunde${hours !== 1 ? "n" : ""}`;
  } else if (deltaSeconds < week) {
    const days = Math.floor(deltaSeconds / day);
    return `vor ${days} Tag${days !== 1 ? "en" : ""}`;
  } else if (deltaSeconds < month) {
    const weeks = Math.floor(deltaSeconds / week);
    return `vor ${weeks} Woche${weeks !== 1 ? "n" : ""}`;
  } else if (deltaSeconds < year) {
    const months = Math.floor(deltaSeconds / month);
    return `vor ${months} Monat${months !== 1 ? "en" : ""}`;
  } else {
    const years = Math.floor(deltaSeconds / year);
    return `vor ${years} Jahr${years !== 1 ? "en" : ""}`;
  }
};

import React from 'react';
import GoogleIcon from '../../assets/images/google-icon.png';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase';
import { isEmailRegistered } from '../../services/authService';
import { getUser, registerUser } from '../../services/userService';
import { extractNames } from '../../utils/helpers';
import useAuthStore from '../../stores/authStore';
import useUserStore from '../../stores/userStore';

import './google-button.css';

interface GoogleButtonProps {
    className?: string | undefined;
    loading: boolean;
}

const GoogleButton: React.FC<GoogleButtonProps> = ({ className, loading }) => {
    // Stores
    const { setFirebaseUser } = useAuthStore();
    const { setUser } = useUserStore();

    const onClickGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

        try {
            const credentials = await signInWithPopup(auth, provider);
            setFirebaseUser(credentials.user);

            const token = await credentials.user.getIdToken();
            const email = credentials.user.email;
            if (email) {
                const existUser = await isEmailRegistered(email)
                if (existUser) {
                    const user = await getUser(token, credentials.user.uid);
                    setUser(user);
                } else {
                    const names = extractNames(credentials.user.displayName);
                    const user = await registerUser(token, credentials.user.uid, email, names.firstName ?? "", names.lastName ?? "");
                    setUser(user);
                }
            }
        } catch (error) {
            console.log('error while trying to login over google:', error);
        }
    }

    return (
        <button className={`${className} googleButton`} onClick={onClickGoogleLogin} disabled={loading}>
            <img
                src={GoogleIcon}
                alt="Google Logo"
                className="googleLogo"
            />
            Anmelden mit Google
        </button>
    );
};

export default GoogleButton;
